import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { dateDifference, getCurrentDateTime, getDateFrom } from 'helpers/dateTimeHelper';
const allTypes = [
    { key: 'law', fileName: 'Laws' },
    { key: 'law-compliance', fileName: 'Law Compliances' },
    { key: 'law-document', fileName: 'Law Documents' },
    { key: 'pro-report', fileName: 'Pro Report' },
    { key: 'custom-report', fileName: 'Custom Report' },
    { key: 'runtime-report', fileName: 'System Runtime Report' },
];
const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const getLawSheetData = (recordList, masterData = {}) => {
    return recordList.map((record, index) => {
        // Type Of Entity
        let entityTypes = [];
        let temp = masterData.entityTypes.some((entityType) => {
            let entityArry = record?.entityTypeId.split(',')?.map(Number);

            entityArry.includes(entityType.id);
            if (entityArry.includes(entityType.id)) {
                entityTypes.push(entityType);
                return entityType;
            }
        });
        let TypeOfEntity = entityTypes
            ?.map((opt) => opt?.entityTypeName)
            .join(';');

        //City
        let cityIds = [];
        let citys = [];

        if (record?.cityId != '') {
            temp = masterData.cities.some((city) => {
                let cityArry = record?.cityId.split(',').map(Number);

                cityArry.includes(city.id);
                if (cityArry.includes(city.id)) {
                    cityIds.push(city);

                    return cityIds;
                }
            });
            citys = cityIds?.map((opt) => opt?.cityName).join(';');
        }

        //facilityTypes
        let facilityTypes = [];
        masterData.facilityTypes.some((facilityType) => {
            let facilityArry = record.facilityTypeId.split(',').map(Number);

            facilityArry.includes(facilityType.id);
            if (facilityArry.includes(facilityType.id)) {
                facilityTypes.push(facilityType);
                return facilityTypes;
            }
        });
        let facilityTypeName = facilityTypes
            ?.map((opt) => opt.facilityTypeName)
            .join(';');

        //Industry
        let industryData = [];
        masterData.industries.some((industryData) => {
            let industryArry = record.industryId.split(',').map(Number);

            industryArry.includes(industryData.id);
            if (industryArry.includes(industryData.id)) {
                industryData.push(industryData);
                return industryData;
            }
        });
        let industry = industryData.map((opt) => opt.industryName).join(';');

        //sub Industry
        let subIndustryData = [];
        masterData.subIndustries.some((subIndustryData) => {
            let subIndustryArry = record.subIndustryId.split(',').map(Number);
            subIndustryArry.includes(subIndustryData.id);
            if (subIndustryArry.includes(subIndustryData.id)) {
                subIndustryData.push(subIndustryData);
                return subIndustryData;
            }
        });
        let subIndustry = subIndustryData.map((opt) => opt.subIndustryName)
            .join(';');

        return {
            'Sr. No': index + 1,
            'Jurisdiction: Federal/National/Central':
                record.country?.countryName,
            'State/Province/Autonomous Region/Prefecture/ Canton':
                record.state?.stateName,
            'Local Body: Municipality/County/City': citys,
            'Category of Law': record.lawCategory?.lawCategoryName,
            'Name of Act/Regulation': record.lawName,
            'Short Name of Act/Regulation': record.lawShortName,
            'Effective Date': record.effectiveDate,
            'Applicability Details': record.applicabilityDetails,
            'Section/Rule/Regulation Referred':
                record?.lawSections != undefined
                    ? record?.lawSections
                        .map((lawSection) => lawSection?.sectionName)
                        ?.join(';')
                    : '',
            'Nature of applicability': record.natureApplicabilityName,
            'Type of Entity': TypeOfEntity,
            'Type of Facility': facilityTypeName,
            Industry: industry,
            'Sub Industry': subIndustry,
            'Web link': record.weblink,
            'Governing Authority Details': record.governingAuthorityDetails,
            Exemptions: record.exemptions,
        };
    });
};
const getLawComplianceSheetData = (recordList) => {
    return recordList.map((record, index) => ({
        'Sr. No': index + 1,
        'Section / Reference': record?.sectionReference,
        'Rule / Reference': record?.ruleReference,
        'Compliance Title': record?.complianceTitle,
        'Compliance Actionable': record?.complianceSpecification,
        'More Details on Compliance': record.complianceDetails,
        'Compliance Class': record?.complianceType?.complianceTypeName,
        'Compliance Classification':
            record.complianceCategory?.complianceCategoryName,
        'Applicability Type': record?.applicabilityConditionName,
        'Compliance Applicability': record.complianceApplicability,
        Frequency: record.periodicity?.periodicityName,
        'Due Date Description': record.dueDateDescription,
        'Parent Event Name': record.event?.eventName,
        'Compliance Period': record.compliancePeriod,
        'Form/Format/E-filing weblink, if any': record.formNo,
        'Filing Mode': record.filingType?.filingTypeName,
        'Need Compliance Evidence?':
            record.isProofRequired === true ? 'YES' : 'NO',
        'Consequence Category': record.penaltyType?.penaltyTypeName,
        'Consequence of Non-Compliance': record.penaltyDescription,
        'Management Personnel Impacted due to Non-Compliance':
            record.impactManagementPersonnel === true ? 'YES' : 'NO',
        'Risk Level': record.riskRating?.riskRatingName,
        'Monetary Penalty Value (Only Numeric)': record.monetaryPenaltyValue,
        'Governing Authority Website': record.governingAuthorityWebsite,
        'Brief Notes (If any)': record.briefNotes,
        'Date of Enforcement': record.enforcementDate,
    }));
};
const getLawDocumentSheetData = (recordList) => {
    return recordList.map((record, index) => ({
        'Sr. No': index + 1,
        'Document Name': record?.documentName,
        'Document Type': record?.documentType.documentTypeName,
        'Reference No': record?.referenceNo,
        'Date of Publication': record?.releaseDate,
        'Effective Date': record.effectiveDate,
        'Description of the Document': record?.documentDescription,
        'Brief Notes (If Any)': record?.briefNote,
    }));
};
const getReportSheetData = (recordList) => {
    return recordList.map((record, index) => ({
        "Sr. No": index + 1,
        "CompanyGroupName": record.companyGroupName,
        "EntityName": record.entityName,
        "Country": record.country,
        "State": record.state,
        "City/Location": record.city,
        "Department": record.department,
        "Law ID": record.lawCode,
        "Short Law Name": record.lawShortName,
        "Compliance ID": record.complianceCode,
        "Compliance Title": record.complianceTitle,
        "Compliance Class": record.complianceType,
        "Compliance Classification": record.complianceCategory,
        "Risk Level": record.riskRating,
        "System Live Date": record.systemLiveDate,
        "Compliance Due Date": record.dueDate ? record.dueDate : "",
        "Actual Compliance Performance Date": record.compliancePerformanceDate ? record.compliancePerformanceDate : "",
        "System Reporting Date": record.systemReportingDate ? record.systemReportingDate : " ",
        "Delay in System Reporting": record.noDelayDays,
        "Status": record.statusName,
        "User Role": record.roleName,
        "Submitter": record.submitter,
        "Reviewer": record.approver1,
        "Approver": record.approver2,
        "HOD": record.hod,
        "Auditor, if Yes": record.auditor
    }));
}
const getProReportSheetData = (recordList) => {
    return recordList.map((record, index) => ({
        "Sr. No": index + 1,
        "CompanyGroupName": record.companyGroupName,
        "EntityName": record.entityName,
        "Country": record.country,
        "State": record.state,
        "City/Location": record.city,
        "Department": record.department,
        "Law ID": record.lawCode,
        "Short Law Name": record.lawShortName,
        "Compliance ID": record.complianceCode,
        "Compliance Title": record.complianceTitle,
        "Compliance Class": record.complianceType,
        "Compliance Classification": record.complianceCategory,
        "Risk Level": record.riskRating,
        "System Live Date": record.systemLiveDate,
        "Compliance Due Date": record.dueDate ? record.dueDate : "",
        "Actual Compliance Performance Date": record.compliancePerformanceDate ? record.compliancePerformanceDate : "",
        "System Reporting Date": record.systemReportingDate ? record.systemReportingDate : " ",
        "Delay in System Reporting": dateDifference(
            record.dueDate,
            record.systemReportingDate
        ),
        "Status": record.statusName,
        "User Role": record.roleName,
        "Submitter": record.submitter,
        "Reviewer": record.approver1,
        "Approver": record.approver2,
        "HOD": record.hod,
        "Auditor, if Yes": record.auditor
    }));
}
const getRuntimeReportSheetData = (recordList) => {
    return recordList.map((record, index) => ({
        "Sr. No": index + 1,
        "Country": record.countryName,
        "Entity Name": record.entityName,
        "Department": record.departmentName,
        "User Name": record.userName,
        "User Role": record.userRole,
        "User Email": record.userEmail,
        "User Contact No": record.userContactNo,
        "Last Login Date": record.lastLoginDate,
        "Last Login Duration": record.totalLoginTime,
        "Total System Usage": record.displayTime,
        "Compliance Score": record.complianceScore
    }));
}
const getDataByModuleType = (propsData, type, masterData) => {
    let exportTableDataSheets = [];
    let filename = '';
    switch (type) {
        case 'law':
            for (let key = 0; key < propsData.length; key++) {
                let exportTableData = { sheetName: '', data: [] };
                const recordList = propsData[key];
                exportTableData.data = getLawSheetData(recordList, masterData);
                exportTableData.sheetName = 'Law';
                exportTableDataSheets.push(exportTableData);
            }
            break;
        case 'law-compliance':
            for (let key = 0; key < propsData.length; key++) {
                let exportTableData = { sheetName: '', data: [] };
                const recordList = propsData[key];
                exportTableData.data = getLawComplianceSheetData(recordList);
                exportTableData.sheetName = 'law-compliance';
                exportTableDataSheets.push(exportTableData);
            }
            break;
        case 'law-document':
            for (let key = 0; key < propsData.length; key++) {
                let exportTableData = { sheetName: '', data: [] };
                const recordList = propsData[key];
                exportTableData.data = getLawDocumentSheetData(recordList);
                exportTableData.sheetName = 'law-document';
                exportTableDataSheets.push(exportTableData);
            }
            break;
        case 'pro-report':
            for (let key = 0; key < propsData.length; key++) {
                let exportTableData = { sheetName: '', data: [] };
                const recordList = propsData[key];
                exportTableData.data = getProReportSheetData(recordList);
                exportTableData.sheetName = 'pro-report';
                exportTableDataSheets.push(exportTableData);
            }
            break;
        case 'custom-report':
            for (let key = 0; key < propsData.length; key++) {
                let exportTableData = { sheetName: '', data: [] };
                const recordList = propsData[key];
                exportTableData.data = getReportSheetData(recordList);
                exportTableData.sheetName = 'custom-report';
                exportTableDataSheets.push(exportTableData);
            }
            break;
        case 'runtime-report':
            for (let key = 0; key < propsData.length; key++) {
                let exportTableData = { sheetName: '', data: [] };
                const recordList = propsData[key];
                exportTableData.data = getRuntimeReportSheetData(recordList);
                exportTableData.sheetName = 'runtime-report';
                exportTableDataSheets.push(exportTableData);
            }
            break;
        default:
            break;
    }
    filename = allTypes.find((opt) => opt.key === type).fileName;
    return { sheets: exportTableDataSheets, fileName: filename };
};
export const exportToCSV = (propsData, type, masterData = {}) => {
    const exportTableData = getDataByModuleType(propsData, type, masterData);

    const dataSheets = exportTableData.sheets;
    const fileName = exportTableData.fileName + '_' + getCurrentDateTime();

    if (dataSheets.length > 0) {
        const sheetNames = [];
        const sheetData = {};
        for (let sheetIndex = 0; sheetIndex < dataSheets.length; sheetIndex++) {
            const sheet = dataSheets[sheetIndex];
            const ws = XLSX.utils.json_to_sheet(sheet.data);
            sheetData[sheet.sheetName] = ws;
            sheetNames.push(sheet.sheetName);
        }
        const wb = {
            Sheets: sheetData,
            SheetNames: sheetNames,
        };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        return FileSaver.saveAs(data, fileName + fileExtension);
    }
};
