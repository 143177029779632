import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { compose } from 'redux';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import ReactTooltip from 'react-tooltip';
import { AUTH_MODE_LC, PENDING_TO_TRIGGERED } from 'constants/commonConstants';
import { getDateFrom } from 'helpers/dateTimeHelper';
import { connect } from 'react-redux';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import { clearComplianceCategoryResponse, clearComplianceTypeResponse, clearRiskRatingResponse, getComplianceCategoriesRequest, getComplianceTypesRequest, getRiskRatingsRequest, getTaskStatusListRequest } from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { countryCoverageListColumns } from 'app/ComplianceLibrary/CountryCoverage/Index';
import __t from 'i18n/translator';
import { AUDITED_AND_CLOSED, CLOSED, PENDING_FOR_AUDIT, PENDING_WITH_FIRST_APPROVER, PENDING_WITH_SECOND_APPROVER, SEND_BACK_BY_AUDITOR_TO_APPROVER, SEND_BACK_TO_APPROVER } from 'constants/databaseConstants';
import { compareString, redirectUrl } from 'helpers/generalUtils';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { selectSortedTaskStatusList } from 'store/Task/selector';
const listColumnsForEntity = [
    {
        label: 'Entity Name',
        field: 'entityName',
    },
    {
        label: 'Group Name',
        field: 'companyGroupName',
    },
    {
        label: 'Register Email ID',
        field: 'email',
    },
    {
        label: 'Contact No',
        field: 'contactNo',
    }
];

const listColumnsForDepartment = [
    {
        label: 'Department Name',
        field: 'departmentName',
    }
];

const listColumnsForUser = [
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Full Name',
        field: 'fullName',
    },
    {
        label: 'User Name',
        field: 'userName',
    },
    {
        label: 'Email ID',
        field: 'email',
    },

    {
        label: 'Contact No',
        field: 'contactNo',
    },
    {
        label: 'Authentication Mode',
        field: 'authenticationMode',
    }
]
const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Status',
        field: 'taskStatusId'
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    }
];

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
class ManagementClickData extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            listColumns: [],
            list: [],
            riskRatingsList: [],
            compliancesTypesList: [],
            compliancesCategoriesList: [],
            taskStatusList: [],
        };
    }
    componentDidMount() {
        this.props.getComplianceCategoriesRequest();
        this.props.getComplianceTypesRequest();
        this.props.getRiskRatingsRequest();
        this.props.getTaskStatusListRequest();
    }
    generateTableForCountry = (records) => {
        let clickRecords = [];
        clickRecords = records.map((lawDataRecord, i) => {
            return {
                countryName: lawDataRecord.countryName,
                laws: lawDataRecord.lawCount,
                compliances: lawDataRecord.complianceCount,
                updates: lawDataRecord?.updateCount
            };
        });
        return clickRecords;
    };
    generateTableForEntity = (records) => {
        let clickRecords = [];
        clickRecords = records.map((entityData, i) => {
            return {
                entityName: entityData.entityName,
                companyGroupName: entityData.companyGroup?.companyGroupName,
                email: entityData.email,
                contactNo: entityData.contactNo ? entityData.contactNo : '',
            };
        });
        return clickRecords;
    };
    generateTableForDepartment = (records) => {
        let clickRecords = [];
        clickRecords = records.map((departmentData, i) => {
            return {
                departmentName: departmentData.departmentName,
            };
        });
        return clickRecords;
    };
    generateTableForUser = (records) => {
        let clickRecords = [];

        clickRecords = records.map((userData, i) => {
            let entityIds = userData?.userEntities?.map((opt) => opt.entityId);
            let entityName = this.props.allEntityList?.filter(item => entityIds.includes(item.value))?.map(x => x.label).join(',');
            return {
                fullName: userData.fullName,
                userName: userData?.userName,
                authenticationMode:
                    userData.authenticationMode === AUTH_MODE_LC
                        ? __t('form_labels.masters.users.lc')
                        : __t('form_labels.masters.users.sso'),
                email: userData.email,
                entityName: entityName,
                contactNo: userData.contactNo,
            };
        });
        return clickRecords;
    };
    getRoleName = (taskData) => {
        let roles = [];
        const departmentIds = (taskData.complianceAllocationDepartmentId != '' && taskData.complianceAllocationDepartmentId != null) ? taskData.complianceAllocationDepartmentId.split(',') : [];
        const collaboratorUserIds = (taskData.collaboratorUserId != '' && taskData.collaboratorUserId != null) ? taskData.collaboratorUserId.split(',').map(Number) : [];
        if (taskData.submitterId == this.state.loginUserId) {
            roles.push('Submitter');
        } else if (taskData.firstApproverUserId == this.state.loginUserId) {
            roles.push('Reviewer');
        } else if (taskData.secondApproverUserId == this.state.loginUserId) {
            roles.push('Approver');
        } else if (taskData.auditorId == this.state.loginUserId) {
            roles.push('Auditor');
        } else if (departmentIds.some((department) => {
            let departmentHod = this.state.userDepartments?.find((departmentEntity) => departmentEntity.entityId == taskData.complianceAllocationEntityId && departmentEntity.departmentId == department);
            if (departmentHod) {
                return true;
            }
        })) {
            roles.push('HOD');
        } else if (taskData.collaboratorUserId != '' && collaboratorUserIds.includes(this.state.loginUserId)
        ) {
            roles.push('Collaborator');
        } else if (taskData.createdBy == this.state.loginUserId) {
            roles.push('Task Creator');
        }
        return roles;
    };
    generateTable = (records) => {
        let taskRecords = [];

        taskRecords = records.map((taskData, i) => {
            const findTaskStatusName = this.state?.taskStatusList
                && this.state?.taskStatusList.length > 0 ? this.state?.taskStatusList.find((element) => element.value == taskData?.taskStatusId)?.label ?? "" : "";
            let roleNames = this.getRoleName(taskData);
            let type = 'report';
            if ([PENDING_FOR_AUDIT, AUDITED_AND_CLOSED].includes(taskData.taskStatusId)) {
                type = 'audit';
            }
            if ([PENDING_WITH_FIRST_APPROVER, PENDING_WITH_SECOND_APPROVER, SEND_BACK_TO_APPROVER, SEND_BACK_BY_AUDITOR_TO_APPROVER].includes(taskData.taskStatusId)) {
                type = 'review';
            }
            let actions = (<div className="tbl_action">
                {(taskData.taskStatusId == CLOSED ||
                    taskData.taskStatusId == AUDITED_AND_CLOSED) && (
                        <a
                            href={() => false}
                            title={__t('common.view')}
                            className="btn btn-link text-success tbl-action"
                            onClick={() => {
                                redirectUrl(this.props.history, '/compliances/' +
                                    type +
                                    '/' +
                                    taskData.id)
                            }}
                        >
                            <i className="fas fa-eye"></i>
                        </a>
                    )}
                {taskData.taskStatusId != CLOSED &&
                    taskData.taskStatusId != AUDITED_AND_CLOSED && (
                        <a
                            href={() => false}
                            title={__t('common.view')}
                            className="btn btn-link text-primary tbl-action"
                            onClick={() => {
                                redirectUrl(this.props.history, '/compliances/' +
                                    type +
                                    '/' +
                                    taskData.id)

                            }}
                        >
                            <i className="fas fa-file-import"></i>
                        </a>
                    )}
            </div>);
            let taskCodeVal = (
                <span searchvalue={taskData.taskCode} className="code_td">{taskData.taskCode}</span>
            );
            let infoVal = (
                <div className='status_info' searchvalue={findTaskStatusName}>
                    <span>{findTaskStatusName}</span>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.props.getTaskUserDetailsByTaskIdRequest(taskData.id)
                        }} className="text-primary"><i className="fas fa-info-circle"></i></a>
                </div>
            );
            let complianceAction = (
                <a searchvalue={taskData?.complianceAllocation?.lawCompliance?.complianceCode}
                    href={() => false}
                    title="View Details"
                    className='tbl-link'
                    onClick={() =>
                        window.open(
                            '/statutory-compliances/view-details/' +
                            taskData?.complianceAllocation?.lawCompliance?.complianceCode,
                            '_blank'
                        )
                    }
                >
                    {taskData?.complianceAllocation?.lawCompliance?.complianceCode}{' '}
                </a>
            )
            let complianceTypeId = (taskData.complianceVersionLawComplianceTypeId == null) ? taskData.complianceAllocationLawComplianceTypeId : taskData.complianceVersionLawComplianceTypeId;
            let complianceTypeName = this.state.compliancesTypesList.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = (taskData.complianceVersionLawComplianceCategoryId == null) ? taskData.complianceAllocationLawComplianceCategoryId : taskData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.compliancesCategoriesList.find((c) => c.value === complianceCategoryId)?.label || '';

            let complianceRiskRatingId = (taskData?.complianceAllocation?.lawCompliance?.riskRatingId == null) ? taskData?.complianceAllocation?.lawCompliance?.riskRatingId : taskData?.complianceAllocation?.lawCompliance?.riskRatingId;
            let complianceRiskRatingName = this.state.riskRatingsList.find((c) => c.value === complianceRiskRatingId)?.label || '';

            return {
                id: taskData.id,
                taskCode: taskCodeVal,
                companyGroupName:
                    taskData?.ComplianceAllocationEntityCompanyGroupName,
                entityName: taskData?.complianceAllocation?.entity?.entityName,
                complianceCode:
                    complianceAction,
                countryName:
                    taskData.complianceAllocationEntityLocationCountryName,
                stateName: taskData.complianceAllocationEntityLocationStateName,
                cityName: taskData?.complianceAllocation?.entityLocation?.city?.cityName,
                complianceTitle:
                    taskData?.complianceAllocation?.lawCompliance?.complianceTitle,
                complianceClass: taskData?.complianceAllocation?.lawCompliance?.complianceCategory?.complianceCategoryName,
                complianceType:
                    complianceTypeName,
                dueDate: (taskData.dueDate) ? <Label searchvalue={taskData.dueDate}>{getDateFrom(taskData.dueDate)}</Label> : <Label searchvalue={''}>{PENDING_TO_TRIGGERED}</Label>,
                riskLevel:
                    complianceRiskRatingName,
                taskStatusId: infoVal,
                parentTaskId:
                    taskData.parentTaskId === null ? 'Statutory' : 'Non-Statutory',
                role: roleNames.length > 0 ? roleNames.join('<br/>') : '-',
                actions: actions,
            };
        });
        return taskRecords;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            this.state.list.length === 0 &&
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList
        ) {
            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            this.state.list.length === 0 &&
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !== nextprops.allCompliancesTypesList
        ) {
            this.setState({
                compliancesTypesList: nextprops.allCompliancesTypesList,
            });
        }

        if (
            this.state.list.length === 0 &&
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                compliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (nextprops.tableData && nextprops.type == 'management-main-overall-country') {
            this.setState(
                {
                    listColumns: countryCoverageListColumns,
                    list: this.generateTableForCountry([]),
                },
                () => {
                    this.setState({
                        list: this.generateTableForCountry(nextprops.tableData),
                    });
                }
            );
        }
        if (nextprops.tableData && nextprops.type == 'management-main-overall-entity') {
            this.setState(
                {
                    listColumns: listColumnsForEntity,
                    list: this.generateTableForEntity([]),
                },
                () => {
                    this.setState({
                        list: this.generateTableForEntity(nextprops.tableData),
                    });
                }
            );
        }
        if (nextprops.tableData && nextprops.type == 'management-main-overall-department') {
            this.setState(
                {
                    listColumns: listColumnsForDepartment,
                    list: this.generateTableForDepartment([]),
                },
                () => {
                    this.setState({
                        list: this.generateTableForDepartment(nextprops.tableData),
                    });
                }
            );
        }

        if (nextprops.tableData && nextprops.type == 'management-main-overall-user') {
            this.setState(
                {
                    listColumns: listColumnsForUser,
                    list: this.generateTableForUser([]),
                },
                () => {
                    this.setState({
                        list: this.generateTableForUser(nextprops.tableData),
                    });
                }
            );
        }
        if (nextprops.tableData && nextprops.type == 'management-main-overall-status') {
            this.setState(
                {
                    listColumns: listColumns,
                    list: this.generateTable([]),
                },
                () => {
                    this.setState({
                        list: this.generateTable(nextprops.tableData),
                    });
                }
            );
        }
        if (
            nextprops.sortedTaskStatusListDropdown &&
            nextprops.sortedTaskStatusListDropdown !==
            this.props.sortedTaskStatusListDropdown
        ) {
            this.setState({
                taskStatusList: nextprops.sortedTaskStatusListDropdown
            })
        }
    }
    render() {
        const data = {
            columns: this.state.listColumns,
            rows: this.state.list,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <Link
                            to="#"
                            className="btn btn-primary mb-3 dashboard_back_btn"
                            onClick={() => {
                                this.props.closeMainClick();
                            }}
                        >
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                    <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Back
                        </Link>
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable
                                    tableRecords={data}
                                    sortRows={[

                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    allCompliancesCategoriesList: makeLawComplianceSelectField('allCompliancesCategoriesList'),
    allCompliancesTypesList: makeLawComplianceSelectField('allCompliancesTypesList'),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
});

const withConnect = connect(mapStatetoProps, {
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    getRiskRatingsRequest,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
    clearRiskRatingResponse,
    getTaskStatusListRequest
});
export default compose(
    withConnect,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(ManagementClickData);
