import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getCountryListByStatusRequest,
    getUserEntityListRequest,
    getDepartmentListByStatusRequest,
    getUserListByEntityAndRoleRequest,
    clearFilterEntityUserResponse,
    getGenerateCertificateRequest,
    getRiskRatingsRequest,
    getCompanyGroupListByStatusRequest,
    getComplianceTypesRequest,
    getTaskStatusListRequest,
    getLawsListByLoginUserRequest,
    getCityListByStateRequest,
    getStateListByCountryRequest,
    getComplianceCategoriesRequest,
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import CertificateFilterForm from './CertificateFilterForm';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import countryReducer from 'store/Masters/Country/reducer';
import countrySaga from 'store/Masters/Country/saga';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import {
    selectSortedEntityList,
    selectSortedUserListByEntityAndRole,
} from 'store/Entity/selector';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import { selectSortedCountryList } from 'store/Masters/Country/selector';
import {
    ACTIVE,
    DANGER,
    PENDING_TO_TRIGGERED,
    SUCCESS,
} from 'constants/commonConstants';
import { initialCertificateFilterDataState } from './CommonFunctions';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import __t from 'i18n/translator';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { listColumns } from './CommonFunctions';
import { makeReportSelectField } from 'store/Report/selector';
import reportReducer from 'store/Report/reducer';
import reportSaga from 'store/Report/saga';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
    DISCREPANCY_COMPLETED_RESOLUTION,
    DISCREPANCY_ONGOING_RESOLUTION,
    DISCREPANCY_PENDING_RESOLUTION,
    DISCREPANCY_TYPE_MONETARY,
    DISCREPANCY_TYPE_NON_MONETARY,
    PENDING_FOR_AUDIT,
    PENDING_FOR_COMPLETION,
} from 'constants/databaseConstants';
import { toastrMessage } from 'helpers/messageHelper';
import { dateDifference, getDateFrom } from 'helpers/dateTimeHelper';
import { makeLawComplianceSelectField, selectSortedComplianceTypesList } from 'store/LawCompliance/selector';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import PdfCertificatesModal from './PdfCertificatesModal';
import { isGTROLE } from 'helpers/projectUtils';
import { selectSortedCompanyGroupList } from 'store/Masters/CompanyGroup/selector';
import companyGroupReducer from 'store/Masters/CompanyGroup/reducer';
import companyGroupSaga from 'store/Masters/CompanyGroup/saga';
import stateReducer from 'store/Masters/State/reducer';
import stateSaga from 'store/Masters/State/saga';
import cityReducer from 'store/Masters/City/reducer';
import citySaga from 'store/Masters/City/saga';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import lawReducer from 'store/Masters/Law/reducer';
import lawSaga from 'store/Masters/Law/saga';
import { selectSortedTaskStatusList } from 'store/Task/selector';
import { selectSortedLawListOfLoginUser } from 'store/Masters/Law/selector';
import { selectSortedStateList } from 'store/Masters/State/selector';
import { selectSortedCityList } from 'store/Masters/City/selector';
import { compareString } from 'helpers/generalUtils';

const countryKey = 'country';
const withCountryReducer = injectReducer({
    key: countryKey,
    reducer: countryReducer,
});
const withCountrySaga = injectSaga({ key: countryKey, saga: countrySaga });
const stateKey = 'state';
const withStateReducer = injectReducer({
    key: stateKey,
    reducer: stateReducer,
});
const withStateSaga = injectSaga({ key: stateKey, saga: stateSaga });
const cityKey = 'city';
const withCityReducer = injectReducer({
    key: cityKey,
    reducer: cityReducer,
});
const withCitySaga = injectSaga({ key: cityKey, saga: citySaga });
const companyGroupKey = 'companyGroup';
const withCompanyGroupReducer = injectReducer({
    key: companyGroupKey,
    reducer: companyGroupReducer,
});
const withCompanyGroupSaga = injectSaga({
    key: companyGroupKey,
    saga: companyGroupSaga,
});
const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});
const reportKey = 'report';
const withReportSaga = injectSaga({ key: reportKey, saga: reportSaga });
const withReportReducer = injectReducer({
    key: reportKey,
    reducer: reportReducer,
});
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });
const lawKey = 'law';
const withLawReducer = injectReducer({
    key: lawKey,
    reducer: lawReducer,
});
const withLawSaga = injectSaga({ key: lawKey, saga: lawSaga });
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isGTROLE: isGTROLE(),
            filterData: initialCertificateFilterDataState(),
            masterData: {},
            userDepartments: [],
            risKRatingChart: [],
            certificateDataList: [],
            countryRows: [],
            departmentChart: [],
            isFormError: false,
            lawRows: [],
            pdfCertificatesData: {},
            uniqueComplianceIds: [],
            showPdfPopup: false,
            allCompliancesTypesList: [],
            allCompliancesCategoriesList: [],
            riskRatingsList: [],
            taskStatusList: []
        };
        this.pdfExportComponent = React.createRef(null);
    }
    componentDidMount() {
        if (!this.state.isGTROLE) {
            this.props.getCompanyGroupListByStatusRequest(ACTIVE);
            this.props.getUserEntityListRequest();
            this.props.getCountryListByStatusRequest(ACTIVE);
            this.props.getDepartmentListByStatusRequest(ACTIVE);
            this.props.getComplianceCategoriesRequest();
            this.props.getComplianceTypesRequest();
            this.props.getRiskRatingsRequest();
            this.props.getTaskStatusListRequest();
            this.props.getLawsListByLoginUserRequest();
        } else {
            this.props.history.push('/home');
        }
    }
    componentWillUnmount() {
        this.props.clearFilterEntityUserResponse();
    }
    getStateListByCountry(countryIds) {
        this.props.getStateListByCountryRequest(countryIds ? countryIds : []);
    }
    getCityListByState(stateIds) {
        this.props.getCityListByStateRequest(stateIds ? stateIds : []);
    }
    getUserListByEntityAndRole(entityIds, roleIds) {
        this.props.getUserListByEntityAndRoleRequest(entityIds, roleIds);
    }
    onFormSubmit = (data) => {
        this.props.getGenerateCertificateRequest({ filter: data });
        this.setState({
            filterData: data,
        });
    };
    groupByEntity = (records) => {
        return Object.values(
            records.reduce(function (res, obj) {
                if (!res[obj.complianceAllocationEntityId]) {
                    res[obj.complianceAllocationEntityId] = {
                        entityId: obj.complianceAllocationEntityId,
                        entityName: obj.complianceAllocationEntityName,
                        cityWiseCount: [],
                    };
                }
                if (
                    !res[obj.complianceAllocationEntityId].cityWiseCount[
                    obj.cityId
                    ]
                ) {
                    res[obj.complianceAllocationEntityId].cityWiseCount[
                        obj.cityId
                    ] = {
                        entityId: obj.complianceAllocationEntityId,
                        entityName: obj.complianceAllocationEntityName,
                        cityId: obj.cityId,
                        cityName: obj.city,
                        allocated: [],
                        reported: [],
                        pending: [],
                        delayed: [],
                        overdue: [],
                    };
                }
                res[obj.complianceAllocationEntityId].cityWiseCount[
                    obj.cityId
                ].allocated.push(obj);
                if (obj.isReportedTask) {
                    res[obj.complianceAllocationEntityId].cityWiseCount[
                        obj.cityId
                    ].reported.push(obj);
                }
                if (obj.isPendingTask) {
                    res[obj.complianceAllocationEntityId].cityWiseCount[
                        obj.cityId
                    ].pending.push(obj);
                }
                if (obj.isDelayTask) {
                    res[obj.complianceAllocationEntityId].cityWiseCount[
                        obj.cityId
                    ].delayed.push(obj);
                }
                if (obj.isOverdueTask) {
                    res[obj.complianceAllocationEntityId].cityWiseCount[
                        obj.cityId
                    ].overdue.push(obj);
                }
                return res;
            }, {})
        );
    };
    resetForm = () => {
        this.setState({
            filterData: initialCertificateFilterDataState(),
        });
    };
    isReportedTask = (task) => {
        return (task.taskStatusId > PENDING_FOR_COMPLETION && task.dueDate &&
            task.dueDate != PENDING_TO_TRIGGERED && task.compliancePerformanceDate <= task.dueDate && (task.auditorId === null || (task.auditorId !== null && task.auditorId !== this.state.loginUserId))) ? true : false;
    }
    isDelayTask = (task) => {
        return (task.dueDate && task.dueDate != PENDING_TO_TRIGGERED && task.compliancePerformanceDate > task.dueDate && (task.auditorId === null || (task.auditorId !== null && task.auditorId !== this.state.loginUserId))) ? true : false
    }
    isPendingObservationTask = (task) => {
        return (task.pendingObservation === 1) ? true : false
    }
    isDiscrepancyResolutionTask = (task) => {
        return ([DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    };
    isDiscrepancyTask = (task) => {
        return ([DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    isDiscrepancyMonetaryTask = (task) => {
        if ([DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId) && task.discrepancyType) {
            let discrepancyTypes = task.discrepancyType.split(',').map(Number);
            return (discrepancyTypes.includes(DISCREPANCY_TYPE_MONETARY)) ? true : false;
        } else {
            return false;
        }
    }
    isDiscrepancyNonMonetaryTask = (task) => {
        if ([DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId) && task.discrepancyType) {
            let discrepancyTypes = task.discrepancyType.split(',').map(Number);
            return (discrepancyTypes.includes(DISCREPANCY_TYPE_NON_MONETARY)) ? true : false;
        } else {
            return false;
        }
    }
    isOverdueTask = (task) => {
        return (task.isOverDue === 1 && ![CLOSED, AUDITED_AND_CLOSED, PENDING_FOR_AUDIT, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    isClosedTask = (task) => {
        return ([CLOSED, AUDITED_AND_CLOSED, PENDING_FOR_AUDIT, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    isInprogressTask = (task) => {
        return (![PENDING_FOR_COMPLETION, CLOSED, PENDING_FOR_AUDIT, AUDITED_AND_CLOSED, DISCREPANCY_PENDING_RESOLUTION, DISCREPANCY_ONGOING_RESOLUTION, DISCREPANCY_COMPLETED_RESOLUTION].includes(task.taskStatusId)) ? true : false
    }
    getRoleName = (taskData, userId, common = true) => {
        let roles = [];
        let roleIds = [];
        const departmentIds =
            taskData.complianceAllocationDepartmentId != '' &&
                taskData.complianceAllocationDepartmentId != null
                ? taskData.complianceAllocationDepartmentId.split(',')
                : [];
        const collaboratorUserIds =
            taskData.collaboratorUserId != '' &&
                taskData.collaboratorUserId != null
                ? taskData.collaboratorUserId.split(',').map(Number)
                : [];
        if (taskData.submitterId == userId) {
            roleIds.push('SUBMITTER');
            roles.push('Submitter');
        } else if (
            taskData.firstApproverUserId !== null &&
            taskData.firstApproverUserId == userId
        ) {
            roleIds.push('FIRST_APPROVER');
            roles.push('Reviewer');
        } else if (
            taskData.secondApproverUserId !== null &&
            taskData.secondApproverUserId == userId
        ) {
            roleIds.push('SECOND_APPROVER');
            roles.push('Approver');
        } else if (
            taskData.auditorId !== null &&
            taskData.auditorId == userId
        ) {
            roleIds.push('AUDITOR');
            roles.push('Auditor');
        } else if (
            departmentIds.some((department) => {
                if (!common) {
                    let departmentHod = this.state.entityDepartments?.find(
                        (departmentEntity) =>
                            departmentEntity.entityId == taskData.entityId &&
                            departmentEntity.departmentId == department &&
                            departmentEntity.userId == userId
                    );
                    if (departmentHod) {
                        return true;
                    }
                } else {
                    let departmentHod = this.state.userDepartments?.find(
                        (departmentEntity) =>
                            departmentEntity.entityId ==
                            taskData.complianceAllocationEntityId &&
                            departmentEntity.departmentId == department
                    );
                    if (departmentHod) {
                        return true;
                    }
                }
            })
        ) {
            roleIds.push('HOD');
            roles.push('HOD');
        } else if (
            taskData.collaboratorUserId !== null &&
            taskData.collaboratorUserId != '' &&
            collaboratorUserIds.includes(userId)
        ) {
            roleIds.push('COLLABORATOR');
            roles.push('Collaborator');
        } else if (
            taskData.createdBy !== null &&
            taskData.createdBy == userId
        ) {
            roles.push('Task Creator');
        }
        return { roleNames: roles, roleIds };
    };
    generateTable = (records) => {
        let reportRecords = [];
        reportRecords = records.map((reportData, i) => {
            const findTaskStatusName = this.state?.taskStatusList
                && this.state?.taskStatusList.length > 0 ? this.state?.taskStatusList.find((element) => element.value == reportData.taskStatusId)?.label ?? "" : "";
            let { roleNames, roleIds } = this.getRoleName(
                reportData,
                this.state.loginUserId,
                true
            );

            const departmentIds =
                reportData.complianceAllocationDepartmentId != '' &&
                    reportData.complianceAllocationDepartmentId != null
                    ? reportData.complianceAllocationDepartmentId
                        .split(',')
                        .map(Number)
                    : [];
            let collaboratorUserIds =
                reportData.collaboratorUserId != '' &&
                    reportData.collaboratorUserId != null
                    ? reportData.collaboratorUserId.split(',').map(Number)
                    : [];
            let dNames = [];
            let hodNames = [];
            const departmentNames =
                this.state.masterData?.entityDepartmentsWithHod?.some((edh) => {
                    if (
                        departmentIds.includes(edh.departmentId) &&
                        edh.entityId == reportData.complianceAllocationEntityId
                    ) {
                        dNames.push(edh.departmentName);
                        let hodNameByDepartment = edh.userNames.split(',');
                        hodNames = [...hodNames, ...hodNameByDepartment];
                    }
                });
            dNames = [...new Set(dNames)];
            hodNames = [...new Set(hodNames)];

            let complianceTypeId = (reportData.complianceVersionLawComplianceTypeId == null) ? reportData.complianceAllocationLawComplianceTypeId : reportData.complianceVersionLawComplianceTypeId;
            let complianceTypeName = this.state.masterData.masterComplianceTypesData.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = (reportData.complianceVersionLawComplianceCategoryId == null) ? reportData.complianceAllocationLawComplianceCategoryId : reportData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.masterData.masterComplianceCategoriesData.find((c) => c.id === complianceCategoryId)?.complianceCategoryName || '';

            let complianceRiskRatingId = reportData.complianceVersionLawComplianceRiskRatingId == null
                ? reportData.complianceAllocationLawComplianceRiskRatingId
                : reportData.complianceVersionLawComplianceRiskRatingId;
            let complianceRiskRatingName = this.state.masterData.masterComplianceRiskRatingsData.find((c) => c.id === complianceRiskRatingId)?.riskRatingName || '';
            let countryName = this.state.masterData.masterCountryData.find((c) => c.id === reportData.complianceAllocationEntityLocationCountryId)?.countryName || '';

            let stateName = this.state.masterData.masterStateData.find((c) => c.id === reportData.complianceAllocationEntityLocationStateId)?.stateName || '';

            let cityName = this.state.masterData.masterCityData.find((c) => c.id === reportData.complianceAllocationEntityLocationCityId)?.cityName || '';
            return {
                companyGroupName:
                    reportData.complianceAllocationEntityCompanyGroupName !== null
                        ? reportData.complianceAllocationEntityCompanyGroupName
                        : '',
                complianceAllocationEntityId:
                    reportData.complianceAllocationEntityId !== null
                        ? reportData.complianceAllocationEntityId
                        : '', isDelayTask: (reportData.dueDate && reportData.dueDate != PENDING_TO_TRIGGERED && reportData.compliancePerformanceDate > reportData.dueDate) ? true : false,
                isDelayTask: this.isDelayTask(reportData),
                isReportedTask: this.isReportedTask(reportData),
                isPendingTask: (reportData.taskStatusId === PENDING_FOR_COMPLETION) ? true : false,
                isPendingAuditTask: (reportData.taskStatusId === PENDING_FOR_AUDIT) ? true : false,
                isAuditedTask: (reportData.taskStatusId === AUDITED_AND_CLOSED) ? true : false,
                isPendingObservationTask: this.isPendingObservationTask(reportData),
                isDiscrepancyTask: this.isDiscrepancyTask(reportData),
                isDiscrepancyResolutionTask: this.isDiscrepancyResolutionTask(reportData),
                isDiscrepancyMonetaryTask: this.isDiscrepancyMonetaryTask(reportData),
                isDiscrepancyNonMonetaryTask: this.isDiscrepancyNonMonetaryTask(reportData),
                isOverdueTask: this.isOverdueTask(reportData),
                isClosedTask: this.isClosedTask(reportData),
                isInprogressTask: this.isInprogressTask(reportData),
                taskStatusId: reportData.taskStatusId !== null ? reportData.taskStatusId : '', countryId:
                    reportData.complianceAllocationEntityLocationCountryId !== null
                        ? reportData.complianceAllocationEntityLocationCountryId
                        : '',
                stateId:
                    reportData.complianceAllocationEntityLocationStateId !== null
                        ? reportData.complianceAllocationEntityLocationStateId
                        : '',
                cityId:
                    reportData.complianceAllocationEntityLocationCityId !== null
                        ? reportData.complianceAllocationEntityLocationCityId
                        : '',
                lawId:
                    reportData.complianceAllocationLawComplianceLawId !== null
                        ? reportData.complianceAllocationLawComplianceLawId
                        : '',
                lawStateId:
                    reportData.complianceAllocationLawComplianceLawStateId !== null
                        ? reportData.complianceAllocationLawComplianceLawStateId
                        : '',
                riskRatingId: complianceRiskRatingName,
                departmentIds: departmentIds?.length ? departmentIds.join(', ') : '',
                entityId: reportData.complianceAllocationEntityId !== null
                    ? reportData.complianceAllocationEntityId
                    : '',
                complianceAllocationLawComplianceId:
                    reportData.complianceAllocationLawComplianceId !== null
                        ? reportData.complianceAllocationLawComplianceId
                        : '',
                companyGroupName:
                    reportData.complianceAllocationEntityCompanyGroupName !==
                        null
                        ? reportData.complianceAllocationEntityCompanyGroupName
                        : '',
                entityName:
                    reportData.complianceAllocationEntityName !== null
                        ? reportData.complianceAllocationEntityName
                        : '',
                country: countryName,
                state: stateName,
                city: cityName,
                department: dNames.join() || "",
                lawCode:
                    reportData.complianceAllocationLawComplianceLawCode !== null
                        ? reportData.complianceAllocationLawComplianceLawCode
                        : '',
                lawShortName:
                    reportData.complianceAllocationLawComplianceLawShortName !==
                        null
                        ? reportData.complianceAllocationLawComplianceLawShortName
                        : '',
                complianceCode:
                    reportData.complianceAllocationLawComplianceCode !== null
                        ? reportData.complianceAllocationLawComplianceCode
                        : '',
                complianceTitle:
                    reportData.parentTaskId === null
                        ? reportData.complianceVersionLawComplianceTitle == null
                            ? reportData.complianceAllocationLawComplianceTitle
                            : reportData.complianceVersionLawComplianceTitle
                        : reportData.title !== null
                            ? reportData.title
                            : '',
                complianceType: complianceTypeName,
                complianceCategory: complianceCategoryName,
                riskRating: complianceRiskRatingName,
                systemLiveDate: reportData?.createdAt ? reportData.createdAt : "",
                dueDate:
                    reportData.dueDate &&
                        reportData.dueDate != PENDING_TO_TRIGGERED
                        ? getDateFrom(reportData.dueDate)
                        : PENDING_TO_TRIGGERED,
                systemReportingDate: reportData.systemReportingDate
                    ? getDateFrom(reportData.systemReportingDate)
                    : '',
                compliancePerformanceDate: reportData.compliancePerformanceDate
                    ? getDateFrom(reportData.compliancePerformanceDate)
                    : '',
                noDelayDays: dateDifference(
                    reportData.dueDate,
                    reportData.systemReportingDate
                ) || '-',
                statusName:
                    findTaskStatusName !== null
                        ? findTaskStatusName
                        : '',
                roleName: roleNames.length > 0 ? roleNames.join(';') : '',
                submitter:
                    reportData.submitterUserName !== null
                        ? reportData.submitterUserName
                        : '',
                approver1:
                    reportData.firstApproverUserName !== null
                        ? reportData.firstApproverUserName
                        : '',
                approver2:
                    reportData.secondApproverUserName !== null
                        ? reportData.secondApproverUserName
                        : '',
                hod: hodNames.length > 0 ? hodNames.join(';') : '',
                auditor:
                    reportData.auditorUserName !== null
                        ? reportData.auditorUserName
                        : '',
            };
        });
        return { reportRecords };
    };
    openPdfGenerate = () => {
        let pdfCertificatesData = { list: [], filters: {} };
        pdfCertificatesData.list = this.state.certificateDataList;
        pdfCertificatesData.filters = this.state.filterData;
        this.setState({
            showPdfPopup: true,
            pdfCertificatesData: pdfCertificatesData,
        });
    }
    closePdfGenerate = () => {
        this.setState({
            showPdfPopup: false,
        });
    }
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                allCompliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !==
            nextprops.allCompliancesTypesList
        ) {
            this.setState({
                allCompliancesTypesList: nextprops.allCompliancesTypesList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.complianceTypeName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList
        ) {
            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }
        if (
            nextprops.generateCertificateResponse &&
            nextprops.generateCertificateResponse !==
            this.props.generateCertificateResponse
        ) {

            if (nextprops.generateCertificateResponse.responseType == SUCCESS) {
                this.setState(
                    {
                        userDepartments:
                            nextprops.generateCertificateResponse?.data
                                ?.userDepartments,
                        masterData:
                            nextprops.generateCertificateResponse.data
                                ?.masterData,
                    },
                    () => {
                        let { reportRecords } = this.generateTable(nextprops.generateCertificateResponse.data?.tasks);
                        this.setState({
                            certificateDataList: reportRecords,
                        });
                    }
                );
            } else {
                toastrMessage(__t('alerts.no_data_for_report'), DANGER);
                this.setState({
                    reportDataList: [],
                    certificateDataList: [],
                });
            }
        }
        if (
            nextprops.sortedTaskStatusListDropdown &&
            nextprops.sortedTaskStatusListDropdown !==
            this.props.sortedTaskStatusListDropdown
        ) {
            this.setState({
                taskStatusList: nextprops.sortedTaskStatusListDropdown
            })
        }
    }
    render() {
        const dataForPro = {
            columns: listColumns,
            rows: this.state.certificateDataList,
        };

        return (
            <React.Fragment>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <CommonBreadcrum type={'generate-certificate'} />
                        </Col>
                        <Col lg="12">
                            <CustomisedCollapseForOne
                                className="accordion"
                                viewDialog={true}
                                dialogName={__t(
                                    'page_headers.generate_certificates'
                                )}
                            >
                                <CardBody className="px-4 pt-4 pb-2">
                                    <CertificateFilterForm
                                        filterData={this.state.filterData}
                                        sortedCompanyGroupListDropdown={this.props.sortedCompanyGroupListDropdown}
                                        sortedCountryListDropdown={
                                            this.props.sortedCountryListDropdown
                                        }
                                        getStateListByCountry={(countryIds) => {
                                            this.getStateListByCountry(countryIds)
                                        }}
                                        sortedStateListDropdown={
                                            this.props.sortedStateListDropdown
                                        }
                                        getCityListByState={(stateIds) =>
                                            this.getCityListByState(stateIds)
                                        }
                                        sortedCityListDropdown={
                                            this.props.sortedCityListDropdown
                                        }
                                        sortedEntityListDropdown={
                                            this.props.sortedEntityListDropdown
                                        }
                                        sortedDepartmentListDropdown={
                                            this.props
                                                .sortedDepartmentListDropdown
                                        }
                                        getUserListByEntityAndRole={(entityIds, roleIds) =>
                                            this.getUserListByEntityAndRole(entityIds, roleIds)
                                        }
                                        sortedUserListByEntityAndRoleDropdown={this.props.sortedUserListByEntityAndRoleDropdown}
                                        allCompliancesCategoriesList={
                                            this.state
                                                .allCompliancesCategoriesList
                                        }
                                        allCompliancesTypesList={
                                            this.state
                                                .allCompliancesTypesList
                                        }
                                        riskRatingsList={
                                            this.state.riskRatingsList
                                        }
                                        sortedLawListOfLoginUserDropdown={this.props.sortedLawListOfLoginUserDropdown}
                                        sortedTaskStatusListDropdown={this.props.sortedTaskStatusListDropdown}
                                        // allCompliancesCategoriesList={
                                        //     this.state
                                        //         .allCompliancesCategoriesList
                                        // }
                                        // riskRatingsList={
                                        //     this.state.riskRatingsList
                                        // }
                                        onFormReset={this.resetForm}
                                        onFormSubmit={this.onFormSubmit}
                                    />
                                </CardBody>
                            </CustomisedCollapseForOne>
                            {this.state.certificateDataList?.length > 0 && (<Card>
                                <CardBody>
                                    <div className="export-btn export_excel_btn">
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                this.openPdfGenerate();
                                            }}
                                        >
                                            Export PDF Certificate
                                            <i class="fas fa-file-pdf fa-lg"></i>
                                        </Button>
                                    </div>
                                    <CustomisedDatatable
                                        entries={50}
                                        tableRecords={dataForPro}
                                    />
                                </CardBody>
                            </Card>)}
                        </Col>
                    </Row>
                </Container>

                <PdfCertificatesModal
                    isModalOpen={this.state.showPdfPopup}
                    pdfCertificatesData={this.state.pdfCertificatesData}
                    onModalDismiss={() => {
                        this.closePdfGenerate()
                    }}
                    masterData={this.state.masterData}
                    riskRatingsList={this.state.riskRatingsList}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    generateCertificateResponse: makeReportSelectField('generateCertificateResponse'),
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    sortedCountryListDropdown: selectSortedCountryList(),
    sortedStateListDropdown: selectSortedStateList(),
    sortedCityListDropdown: selectSortedCityList(),
    sortedEntityListDropdown: selectSortedEntityList(),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    sortedUserListByEntityAndRoleDropdown: selectSortedUserListByEntityAndRole(),
    sortedCompanyGroupListDropdown: selectSortedCompanyGroupList(),
    allCompliancesCategoriesList: makeLawComplianceSelectField('allCompliancesCategoriesList'),
    allCompliancesTypesList: makeLawComplianceSelectField('allCompliancesTypesList'),
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
    sortedLawListOfLoginUserDropdown: selectSortedLawListOfLoginUser(),
});
const withConnect = connect(mapStatetoProps, {
    getGenerateCertificateRequest,
    getCountryListByStatusRequest,
    getStateListByCountryRequest,
    getCityListByStateRequest,
    getUserEntityListRequest,
    getDepartmentListByStatusRequest,
    getUserListByEntityAndRoleRequest,
    clearFilterEntityUserResponse,
    getRiskRatingsRequest,
    getComplianceTypesRequest,
    getComplianceCategoriesRequest,
    getTaskStatusListRequest,
    getLawsListByLoginUserRequest,
    getCompanyGroupListByStatusRequest
});
export default compose(
    withConnect,
    withCompanyGroupReducer,
    withCompanyGroupSaga,
    withCountryReducer,
    withCountrySaga,
    withStateReducer,
    withStateSaga,
    withCityReducer,
    withCitySaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withEntityReducer,
    withEntitySaga,
    withReportReducer,
    withReportSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withLawReducer,
    withLawSaga,
    withTaskReducer,
    withTaskSaga,
    withRouter
)(Index);
