import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, Label, FormGroup } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import entityReducer from 'store/Entity/reducer';
import entitySaga from 'store/Entity/saga';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import {
    getReportTaskSearchListRequest,
    getReviewTaskSearchListRequest,
    getAuditTaskSearchListRequest,
    getDepartmentListByStatusRequest,
    getRiskRatingsRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    clearRiskRatingResponse,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
    sendForAuditTaskByTaskIdsRequest,
    clearTaskSearchApiResponse,
    getTaskStatusListRequest
} from 'store/actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
    initialTaskFilterDataState,
    listAuditColumns,
    listColumns,
    listColumnsWithCheckbox,
} from './CommonFunctions';
import __t from 'i18n/translator';
import {
    ACTIVE,
    DANGER,
    DEFAULT_PAGE_LENGTH,
    PENDING_TO_TRIGGERED,
    SUCCESS,
} from 'constants/commonConstants';
import TaskFilterForm from './TaskFilterForm';
import { makeTaskSelectField, selectSortedTaskStatusList } from 'store/Task/selector';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
    COMPLIANCE_CATEGORY_EVENT_BASED,
    COMPLIANCE_CATEGORY_ONE_TIME,
    DISCREPANCY_COMPLETED_RESOLUTION,
    DISCREPANCY_ONGOING_RESOLUTION,
    DISCREPANCY_PENDING_RESOLUTION,
    PENDING_FOR_COMPLETION,
} from 'constants/databaseConstants';
import CustomisedCollapseForOne from 'components/Application/CustomisedCollapseForOne';
import { getAuditStatusById, getTaskStatusById, isManagementRole } from 'helpers/projectUtils';
import CustomisedReactTableWithPaginationAndRowSelection from 'components/Application/CustomisedReactTableWithPaginationAndRowSelection';
import QuickSelectedUpdateModal from './QuickSelectedUpdateModal';
import { toastrMessage } from 'helpers/messageHelper';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import { listTypeForTaskDropdown } from 'constants/dropdowns';
import Select from 'react-select';
import { formatDateForApiSystem } from 'helpers/dateTimeHelper';
import { selectSortedDepartmentList } from 'store/Masters/Department/selector';
import departmentReducer from 'store/Masters/Department/reducer';
import departmentSaga from 'store/Masters/Department/saga';
import SubTaskList from '../SubTaskList';
import CommonBreadcrum from 'components/Application/CommonBreadcrum';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import { compareString, redirectUrl } from 'helpers/generalUtils';
import BulkSendAuditModal from './BulkSendAuditModal';
import { getValueByKey } from 'helpers/authUtils';
import * as authConstants from 'constants/authConstants';
const departmentKey = 'department';
const withDepartmentReducer = injectReducer({
    key: departmentKey,
    reducer: departmentReducer,
});
const withDepartmentSaga = injectSaga({
    key: departmentKey,
    saga: departmentSaga,
});

const entityKey = 'entity';
const withEntityReducer = injectReducer({
    key: entityKey,
    reducer: entityReducer,
});
const withEntitySaga = injectSaga({ key: entityKey, saga: entitySaga });

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });

const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const getPageHeaderName = (type) => {
    let pageHeaderName = '';
    switch (type) {
        case 'report':
            pageHeaderName = __t('page_headers.report_compliances');
            break;
        case 'review':
            pageHeaderName = __t('page_headers.review_compliances');
            break;
        case 'audit':
            pageHeaderName = __t('page_headers.audit_compliances');
            break;
        default:
            pageHeaderName = '';
            break;
    }
    return pageHeaderName;
};
class Index extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        var lastParam = this.props.location.pathname.split('/')?.slice(-1)[0];
        this.state = {
            pageType: lastParam,
            pageHeaderName: getPageHeaderName(lastParam),
            loginUserId: parseInt(getValueByKey(authConstants.USER_ID)),
            taskList: [],
            userDepartments: [],
            searchFormData: initialTaskFilterDataState(),
            pageSize: DEFAULT_PAGE_LENGTH,
            pageCount: 0,
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            currentPage: 1,
            resetTableSection: false,
            totalRecords: 0,
            selectedTasks: [],
            quickUpdateUserModal: false,
            quickUpdateSelectedData: [],
            bulkUpdate: false,
            openTaskData: {},
            isOpenSubTaskModal: false,
            riskRatingsList: [],
            compliancesCategoriesList: [],
            compliancesTypesList: [],
            countryStateCityData: [],
            bulkUpdateForAudit: false,
            enableForHOD: false,
            isSendAuditModalOpen: false,
            entityId: 0,
            bulkSendTaskList: [],
            bulkSendTaskUsers: [],
            taskStatusList: [],
        };
        this.handleFetchData = this.handleFetchData.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }
    componentDidMount() {
        this.props.getRiskRatingsRequest();
        this.props.getComplianceCategoriesRequest();
        this.props.getComplianceTypesRequest();
        this.props.getTaskStatusListRequest();

        this.props.getDepartmentListByStatusRequest(ACTIVE);
        this.getTaskSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            {},
        );
    }
    componentWillUnmount() {
        this.props.clearTaskSearchApiResponse();
        this.props.clearRiskRatingResponse();
        this.props.clearComplianceTypeResponse();
        this.props.clearComplianceCategoryResponse();
    }
    getTaskSearchList = (limit, page, sortBy, values = {}) => {
        let apiData = {
            limit: -1,
            page: -1,
            filter: { ...values, isManagementUser: isManagementRole() },
            orderBy: sortBy,
        };
        switch (this.state.pageType) {
            case 'report':
                this.props.getReportTaskSearchListRequest(apiData, true);
                break;
            case 'review':
                this.props.getReviewTaskSearchListRequest(apiData, true);
                break;
            case 'audit':
                this.props.getAuditTaskSearchListRequest(apiData, true);
                break;
            default:
                break;
        }
    };
    formSubmit = (values, dateModify = false) => {
        this.setState(
            {
                taskList: [],
                resetTableSection: true,
                searchFormData: {
                    ...values,
                    fromDate:
                        values.fromDate && dateModify
                            ? formatDateForApiSystem(values.fromDate)
                            : '',
                    toDate:
                        values.toDate && dateModify
                            ? formatDateForApiSystem(values.toDate)
                            : '',
                },
            },
            () => {
                this.fetchTaskSearchList();
            }
        );
    };
    resetForm = () => {
        this.setState(
            {
                taskList: [],
                searchFormData: initialTaskFilterDataState(),
                currentPage: 1,
                resetTableSection: true,
            },
            () => {
                this.getTaskSearchList(this.state.pageSize, 1, {
                    key: 'createdAt',
                    value: 'DESC',
                }, {});
            }
        );
    };
    handleFetchData(data) {
        if (
            data.pageIndex + 1 !== this.state.currentPage ||
            this.state.pageSize !== data.pageSize
        ) {
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                },
                () => {
                    this.getTaskSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        this.state.sortBy,
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSort(data) {
        if (
            data.sortBy[0]?.desc !== undefined &&
            data.sortBy[0]?.desc !== this.state.sortBy?.value
        ) {
            let sortOrder = data.sortBy[0]?.desc ? 'DESC' : 'ASC';
            this.setState(
                {
                    pageSize: data.pageSize,
                    searchFormData: {
                        ...this.state.searchFormData,
                        searchText: data.globalFilter,
                    },
                    sortBy: { key: data.sortBy[0]?.id, value: sortOrder },
                },
                () => {
                    this.getTaskSearchList(
                        data.pageSize,
                        data.pageIndex + 1,
                        { key: data.sortBy[0]?.id, value: sortOrder },
                        this.state.searchFormData,
                    );
                }
            );
        }
    }
    handleSearch(data) {
        this.setState(
            {
                searchFormData: {
                    ...this.state.searchFormData,
                    searchText: data.globalFilter,
                },
            },
            () => {
                this.fetchTaskSearchList();
            }
        );
    }
    fetchTaskSearchList = () => {
        this.getTaskSearchList(this.state.pageSize,
            1,
            this.state.sortBy,
            this.state.searchFormData);
    };
    openSubTaskModal = (taskData) => {
        this.setState({
            openTaskData: taskData,
            isOpenSubTaskModal: true,
        });
    };
    closeSubTaskModal = () => {
        this.setState({
            openTaskData: {},
            isOpenSubTaskModal: false,
        });
    };
    getRoleName = (taskData) => {
        let roles = [];
        const departmentIds =
            taskData.complianceAllocationDepartmentId != '' &&
                taskData.complianceAllocationDepartmentId != null
                ? taskData.complianceAllocationDepartmentId.split(',')
                : [];
        const collaboratorUserIds =
            taskData.collaboratorUserId != '' &&
                taskData.collaboratorUserId != null
                ? taskData.collaboratorUserId.split(',').map(Number)
                : [];
        if (taskData.submitterId == this.state.loginUserId) {
            roles.push('Submitter');
        } else if (taskData.firstApproverUserId == this.state.loginUserId) {
            roles.push('Reviewer');
        } else if (taskData.secondApproverUserId == this.state.loginUserId) {
            roles.push('Approver');
        } else if (taskData.auditorId == this.state.loginUserId) {
            roles.push('Auditor');
        } else if (
            departmentIds.some((department) => {
                let departmentHod = this.state.userDepartments?.find(
                    (departmentEntity) =>
                        departmentEntity.entityId ==
                        taskData.complianceAllocationEntityId &&
                        departmentEntity.departmentId == department
                );
                if (departmentHod) {
                    return true;
                }
            })
        ) {
            roles.push('HOD');
        } else if (
            taskData.collaboratorUserId != '' &&
            collaboratorUserIds.includes(this.state.loginUserId)
        ) {
            roles.push('Collaborator');
        } else if (taskData.createdBy == this.state.loginUserId) {
            roles.push('Task Creator');
        }
        return roles;
    };
    generateTable = (records) => {
        let taskRecords = [];
        let enableForHOD = false;
        taskRecords = records.map((taskData, i) => {
            const findTaskStatusName = this.state?.taskStatusList
                && this.state?.taskStatusList.length > 0 ? this.state?.taskStatusList.find((element) => element.value == taskData.taskStatusId)?.label ?? "" : "";
            let taskStatusInfo = getTaskStatusById(taskData.taskStatusId, findTaskStatusName);
            let auditStatusInfo = getAuditStatusById(taskData.taskStatusId, findTaskStatusName);
            let roleNames = this.getRoleName(taskData);
            if (roleNames.includes('HOD')) {
                enableForHOD = true;
            }
            let actions = (
                <div className="tbl_action">
                    {(taskData.taskStatusId === CLOSED ||
                        taskData.taskStatusId === AUDITED_AND_CLOSED ||
                        taskData.taskStatusId === DISCREPANCY_PENDING_RESOLUTION ||
                        taskData.taskStatusId === DISCREPANCY_ONGOING_RESOLUTION ||
                        taskData.taskStatusId === DISCREPANCY_COMPLETED_RESOLUTION) &&
                        (
                            <a
                                href={() => false}
                                title={__t('common.view')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    redirectUrl(
                                        this.props.history,
                                        '/compliances/' +
                                        this.state.pageType +
                                        '/' +
                                        taskData.id
                                    );
                                }}
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                        )}
                    {taskData.taskStatusId != CLOSED &&
                        taskData.taskStatusId != AUDITED_AND_CLOSED &&
                        taskData.taskStatusId != DISCREPANCY_PENDING_RESOLUTION &&
                        taskData.taskStatusId != DISCREPANCY_ONGOING_RESOLUTION &&
                        taskData.taskStatusId != DISCREPANCY_COMPLETED_RESOLUTION && (
                            <a
                                href={() => false}
                                title={
                                    this.state.pageType == 'report' &&
                                        taskData.taskStatusId !==
                                        PENDING_FOR_COMPLETION
                                        ? __t('tables.tasks.rework')
                                        : __t('tables.tasks.reporting')
                                }
                                className="btn btn-link text-primary tbl-action"
                                onClick={() => {
                                    redirectUrl(
                                        this.props.history,
                                        '/compliances/' +
                                        this.state.pageType +
                                        '/' +
                                        taskData.id
                                    );
                                }}
                            >
                                {this.state.pageType == 'report' &&
                                    taskData.taskStatusId !==
                                    PENDING_FOR_COMPLETION ? (
                                    <i className="fas fa-undo"></i>
                                ) : (
                                    <i className="fas fa-file-import"></i>
                                )}
                            </a>
                        )}

                    {taskData?.subTaskCount > 0 && (
                        <a
                            href={() => false}
                            className="text-info subtask_icon"
                            title="Sub-task view"
                            onClick={() => {
                                this.openSubTaskModal(taskData);
                            }}
                        >
                            <svg
                                width="17"
                                height="20"
                                viewBox="0 0 17 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="16"
                                    y="7"
                                    width="4"
                                    height="4"
                                    rx="2"
                                    transform="rotate(90 16 7)"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                />
                                <rect
                                    x="16"
                                    y="15"
                                    width="4"
                                    height="4"
                                    rx="2"
                                    transform="rotate(90 16 15)"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                />
                                <rect
                                    x="1"
                                    y="5"
                                    width="4"
                                    height="4"
                                    rx="2"
                                    transform="rotate(-90 1 5)"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                />
                                <path
                                    d="M3 6V13C3 14.8856 3 15.8284 3.58579 16.4142C4.17157 17 5.11438 17 7 17H12"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                />
                                <path
                                    d="M3 5V5C3 6.88562 3 7.82843 3.58579 8.41421C4.17157 9 5.11438 9 7 9H12"
                                    stroke="CurrentColor"
                                    strokeWidth="2"
                                />
                            </svg>
                        </a>
                    )}
                </div>
            );
            let taskCodeVal = (
                <span searchvalue={taskData.taskCode} className="code_td">
                    {taskData.taskCode}
                </span>
            );
            let complianceAction = (
                <a
                    href={() => false}
                    searchvalue={
                        taskData?.complianceAllocationLawComplianceCode
                    }
                    title="View Details"
                    className="tbl-link"
                    onClick={() =>
                        window.open(
                            '/statutory-compliances/view-details/' +
                            taskData?.complianceAllocationLawComplianceId,
                            '_blank'
                        )
                    }
                >
                    {taskData?.complianceAllocationLawComplianceCode}{' '}
                </a>
            );
            let disableCheckbox = false;
            if (
                taskData.complianceAllocationLawComplianceCategoryId ===
                COMPLIANCE_CATEGORY_EVENT_BASED ||
                (taskData.complianceAllocationLawComplianceCategoryId ===
                    COMPLIANCE_CATEGORY_ONE_TIME)
                || (taskData.complianceAllocationLawComplianceIsActive == 0)
            ) {
                disableCheckbox = true;
            }
            if (
                taskData.complianceVersionLawComplianceCategoryName != null
            ) {
                if (
                    taskData.complianceVersionLawComplianceCategoryId ===
                    COMPLIANCE_CATEGORY_EVENT_BASED ||
                    (taskData.complianceVersionLawComplianceCategoryId ===
                        COMPLIANCE_CATEGORY_ONE_TIME) ||
                    (taskData.complianceVersionLawComplianceIsActive == 0)
                ) {
                    disableCheckbox = true;
                }
            }
            let auditStatusVal = (
                <div
                    className="audit-status"
                    searchvalue={auditStatusInfo}
                >
                    <span>{auditStatusInfo}</span>
                    {(taskData.taskStatusId === DISCREPANCY_PENDING_RESOLUTION ||
                        taskData.taskStatusId === DISCREPANCY_ONGOING_RESOLUTION ||
                        taskData.taskStatusId === DISCREPANCY_COMPLETED_RESOLUTION
                    ) && (
                            <a
                                href={() => false}
                                className="text-danger"
                                title={__t('form_labels.reporting.discrepancy_found')}
                            >
                                <i className="fas fa-flag"></i>
                            </a>
                        )}
                </div>
            );
            const cityName = this.state.countryStateCityData.masterCityData?.filter((data) => data.id === taskData.complianceAllocationEntityLocationCityId)[0]?.cityName
            const stateName = this.state.countryStateCityData.masterStateData?.filter((data) => data.id === taskData.complianceAllocationEntityLocationStateId)[0]?.stateName
            const countryName = this.state.countryStateCityData.masterCountryData?.filter((data) => data.id === taskData.complianceAllocationEntityLocationCountryId)[0]?.countryName

            let complianceTypeId = taskData.complianceVersionLawComplianceTypeId == null
                ? taskData.complianceAllocationLawComplianceTypeId
                : taskData.complianceVersionLawComplianceTypeId;
            let complianceTypeName = this.state.compliancesTypesList.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = taskData.complianceVersionLawComplianceCategoryId == null
                ? taskData.complianceAllocationLawComplianceCategoryId
                : taskData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.compliancesCategoriesList.find((c) => c.value === complianceCategoryId)?.label || '';

            let complianceRiskRatingId = taskData.complianceVersionLawComplianceRiskRatingId == null
                ? taskData.complianceAllocationLawComplianceRiskRatingId
                : taskData.complianceVersionLawComplianceRiskRatingId;
            let complianceRiskRatingName = this.state.riskRatingsList.find((c) => c.value === complianceRiskRatingId)?.label || '';

            if (this.state.pageType === 'audit' && enableForHOD === true && taskData.taskStatusId === CLOSED) {
                disableCheckbox = false
            }
            return {
                checkbox: taskData.dueDate && !disableCheckbox ? true : false,
                id: taskData.id,
                submitterId: (taskData?.submitterId) ? taskData?.submitterId : "",
                firstApproverUserId: (taskData?.firstApproverUserId) ? taskData?.firstApproverUserId : "",
                secondApproverUserId: (taskData?.secondApproverUserId) ? taskData?.secondApproverUserId : "",
                taskCode: taskCodeVal,
                entityId: taskData?.complianceAllocationEntityId,
                entityName: taskData?.complianceAllocationEntityName,
                complianceCode: complianceAction,
                countryName: countryName ? countryName : "",
                stateName: stateName ? stateName : "",
                cityName: cityName ? cityName : "",
                complianceTitle:
                    taskData.parentTaskId === null
                        ? taskData.complianceVersionLawComplianceTitle == null
                            ? taskData?.complianceAllocationLawComplianceTitle
                            : taskData.complianceVersionLawComplianceTitle
                        : taskData.title,
                riskLevel: complianceRiskRatingName,
                complianceClass: complianceCategoryName,
                complianceType: complianceTypeName,
                proofRequired:
                    taskData.complianceVersionLawComplianceIsProofRequired ==
                        null
                        ? taskData.complianceAllocationLawComplianceIsProofRequired
                        : taskData.complianceVersionLawComplianceIsProofRequired,
                dueDate: taskData.dueDate ? (
                    <Label
                        searchvalue={formatDateForApiSystem(taskData.dueDate)}
                    >
                        {taskData.dueDate}
                    </Label>
                ) : (
                    <Label searchvalue={''}>{PENDING_TO_TRIGGERED}</Label>
                ),
                statusId: taskData?.taskStatusId,
                taskStatusId: taskStatusInfo,
                auditStatus: auditStatusVal,
                complianceAllocationDepartmentId:
                    taskData?.complianceAllocationDepartmentId &&
                        taskData?.complianceAllocationDepartmentId !== null
                        ? taskData?.complianceAllocationDepartmentId
                        : '',
                parentTaskId:
                    taskData.parentTaskId === null
                        ? 'Statutory'
                        : 'Non-Statutory',
                actions: actions,
            };
        });
        return { taskRecords, enableForHOD };
    };
    openQuickUpdateModal = () => {
        let selectedCheckboxIds;
        selectedCheckboxIds = this.state.selectedTasks.map((x) => x.id);
        selectedCheckboxIds = this.state.taskList?.filter((e) =>
            selectedCheckboxIds.includes(e.id)
        );
        this.setState(
            {
                quickUpdateSelectedData: selectedCheckboxIds,
            },
            () => {
                this.setState({
                    quickUpdateUserModal: true,
                });
            }
        );
    };
    closeQuickUpdateModal = () => {
        this.setState(
            {
                resetTableSection: true,
                quickUpdateUserModal: false,
                quickUpdateSelectedData: [],
                selectedTasks: [],
            },
            () => {
                this.fetchTaskSearchListWithOptions();
            }
        );
    };

    fetchTaskSearchListWithOptions = () => {
        this.getTaskSearchList(
            this.state.pageSize,
            this.state.currentPage,
            this.state.sortBy,
            {},
        );
    };
    bulkSend = () => {
        let sendAuditTaskList = this.state.taskList.filter((t) => t.checkbox && t.statusId === CLOSED && t.parentTaskId === 'Statutory');
        this.setState({
            bulkUpdateForAudit: !this.state.bulkUpdateForAudit,
            bulkSendTaskList: sendAuditTaskList,
            bulkSendTaskUsers: []
        }, () => {
            this.setState({
                selectedTasks: this.state.bulkUpdateForAudit === false ? [] : sendAuditTaskList.map((t) => ({ id: t.id, entityId: t.entityId })),
                entityId: 0
            });
        });
    }
    openSendAuditModal = () => {
        let selectedEntityIds = this.state.selectedTasks.map((x) => x.entityId);
        selectedEntityIds = [...new Set(selectedEntityIds)];
        if (selectedEntityIds.length > 1) {
            toastrMessage(__t('alerts.task_bulk_audit'), DANGER);
            return false;
        }
        let selectedCheckboxIds = this.state.selectedTasks.map((x) => x.id);
        let bulkSendTaskUsers = [];
        this.state.bulkSendTaskList.forEach(t => {
            if (selectedCheckboxIds.includes(t.id)) {
                if (t.submitterId !== null && t.submitterId !== '') {
                    bulkSendTaskUsers.push(t.submitterId);
                }
                if (t.firstApproverUserId !== null && t.firstApproverUserId !== '') {
                    bulkSendTaskUsers.push(t.firstApproverUserId);
                }
                if (t.secondApproverUserId !== null && t.secondApproverUserId !== '') {
                    bulkSendTaskUsers.push(t.secondApproverUserId);
                }
                return true;
            }
        });

        this.setState({
            bulkSendTaskUsers: bulkSendTaskUsers.map(Number),
            isSendAuditModalOpen: true,
            taskIds: selectedCheckboxIds,
            entityId: selectedEntityIds[0]
        });
    };
    closeSendAuditModal = () => {
        this.setState({
            isSendAuditModalOpen: false
        });
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        var lastParam = this.props.location.pathname.split('/')?.slice(-1)[0];
        var nextLastParam = nextprops.location.pathname
            .split('/')
            ?.slice(-1)[0];

        if (nextLastParam && nextLastParam !== lastParam) {
            this.setState(
                {
                    pageType: nextLastParam,
                    pageHeaderName: getPageHeaderName(nextLastParam),
                    currentPage: 1,
                    pageSize: DEFAULT_PAGE_LENGTH,
                    sortBy: {
                        key: 'createdAt',
                        value: 'DESC',
                    },
                },
                () => {

                    this.fetchTaskSearchListWithOptions();
                }
            );
        }


        if (
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList
            && this.state.taskList.length === 0
        ) {

            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !== nextprops.allCompliancesTypesList
            && this.state.taskList.length === 0
        ) {
            this.setState({
                compliancesTypesList: nextprops.allCompliancesTypesList,
            });
        }

        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList &&
            this.state.taskList.length === 0
        ) {
            this.setState({
                compliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (
            this.state.taskList.length === 0 &&
            nextprops.allCompliancesCategoriesList &&
            nextprops.allCompliancesTypesList &&
            nextprops.riskRatingsList &&
            nextprops.taskSearchApiResponse
        ) {
            this.setState({
                userDepartments:
                    nextprops.taskSearchApiResponse.data
                        .userDepartments,
                taskList: [],
                countryStateCityData: nextprops.taskSearchApiResponse.data.countryStateCityData,
            }, () => {
                let { taskRecords, enableForHOD } = this.generateTable(nextprops.taskSearchApiResponse.data.tasks);

                this.setState({
                    resetTableSection: false,
                    taskList: taskRecords,
                    enableForHOD: enableForHOD
                });
            })
        }

        if (
            nextprops.quickUpdateTaskApiResponse &&
            this.props.quickUpdateTaskApiResponse !==
            nextprops.quickUpdateTaskApiResponse
        ) {
            toastrMessage(
                nextprops.quickUpdateTaskApiResponse.message,
                nextprops.quickUpdateTaskApiResponse.responseType
            );
            if (nextprops.quickUpdateTaskApiResponse.responseType === SUCCESS) {
                this.closeQuickUpdateModal();
            }
        }
        if (nextprops.taskSendAuditApiResponse && this.props.taskSendAuditApiResponse !== nextprops.taskSendAuditApiResponse) {
            toastrMessage(nextprops.taskSendAuditApiResponse.message, nextprops.taskSendAuditApiResponse.responseType);
            if (nextprops.taskSendAuditApiResponse.responseType === SUCCESS) {
                // this.closeSendAuditModal();
                this.setState({
                    bulkUpdateForAudit: false,
                    enableForHOD: false,
                    selectedTasks: [],
                    isSendAuditModalOpen: false,
                    entityId: 0,
                    bulkSendTaskList: [],
                    taskList: []
                }, () => {
                    this.getTaskSearchList(
                        this.state.pageSize,
                        this.state.currentPage,
                        this.state.sortBy,
                        {},
                    );
                })
            }
        }
        if (
            nextprops.sortedTaskStatusListDropdown &&
            nextprops.sortedTaskStatusListDropdown !==
            this.props.sortedTaskStatusListDropdown
        ) {
            this.setState({
                taskStatusList: nextprops.sortedTaskStatusListDropdown
            })
        }
    }

    render() {
        const data = {
            columns: (this.state.pageType === 'audit') ? listAuditColumns : listColumns,
            rows: this.state.taskList,
        };
        const dataWithCheckbox = {
            columns: listColumnsWithCheckbox,
            rows: (this.state.pageType === 'audit' && this.state.enableForHOD === true) ? this.state.bulkSendTaskList : this.state.taskList,
        };
        return (
            <React.Fragment>
                <Row>
                    <Col lg="12">
                        <CommonBreadcrum
                            type={this.state.pageType + '-compliances'}
                        />
                    </Col>
                    <Col lg="12">
                        <CustomisedCollapseForOne
                            className="accordion"
                            viewDialog={false}
                            dialogName={this.state.pageHeaderName}
                        >
                            <CardBody className="px-4 pt-4 pb-2">
                                <TaskFilterForm
                                    taskFilterData={this.state.searchFormData}
                                    pageType={this.state.pageType}
                                    operationType={this.state.operationType}
                                    onFormSubmit={(values) => {
                                        this.formSubmit(values, true);
                                    }}
                                    riskRatingsList={
                                        this.state.riskRatingsList
                                    }
                                    onFormReset={this.resetForm}
                                />
                            </CardBody>
                        </CustomisedCollapseForOne>
                    </Col>

                    <>
                        <Col lg="12">
                            <Card>
                                <CardBody
                                    className={
                                        (this.state.pageType === 'report' || (this.state.pageType === 'audit' && this.state.enableForHOD === true))
                                            ? 'custom_table report_compliances'
                                            : 'custom_table'
                                    }
                                >
                                    {this.state.pageType !== 'audit' && (
                                        <div className="report-select-box">
                                            <FormGroup>
                                                <Select
                                                    name="listType"
                                                    className="form-control selectbox"
                                                    options={
                                                        listTypeForTaskDropdown
                                                    }
                                                    onChange={(opt) => {
                                                        let listTypeVal = opt && opt?.value ? opt.value : '';
                                                        this.formSubmit({
                                                            ...this.state.searchFormData,
                                                            listType: listTypeVal,
                                                        }, false);
                                                    }}
                                                    value={listTypeForTaskDropdown.filter(
                                                        (option) =>
                                                            this.state.searchFormData?.listType == option.value
                                                    )}
                                                    closeMenuOnSelect={true}
                                                    isClearable={true}
                                                />
                                                <Label className="form-label">
                                                    {' '}
                                                    {__t('form_labels.task_filters.list_type')}
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    )}
                                    {this.state.pageType == 'report' && (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="bulk_update pl-2"
                                            onClick={() => {
                                                this.setState({
                                                    bulkUpdate:
                                                        this.state
                                                            .bulkUpdate === true
                                                            ? false
                                                            : true,
                                                    selectedTasks:
                                                        this.state
                                                            .bulkUpdate ===
                                                            false
                                                            ? []
                                                            : this.state
                                                                .bulkUpdate,
                                                });
                                            }}
                                        >
                                            <i
                                                className={
                                                    this.state.bulkUpdate ===
                                                        false
                                                        ? 'fas fa-sync'
                                                        : 'fas fa-list-ul'
                                                }
                                            ></i>
                                            {this.state.bulkUpdate === false
                                                ? 'Bulk update'
                                                : 'List view'}
                                        </Button>
                                    )}
                                    {this.state.pageType == 'report' && this.state.selectedTasks.length >
                                        0 && (
                                            <>
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="quickupdate_btn position-absolute"
                                                    onClick={() => {
                                                        this.openQuickUpdateModal();
                                                    }}
                                                >
                                                    <i className="fas fa-user-edit"></i>
                                                    Quick Update
                                                </Button>
                                            </>
                                        )}
                                    {this.state.pageType == 'audit' && this.state.selectedTasks.length >
                                        0 && (
                                            <Button
                                                type="submit"
                                                color="success"
                                                className="quickupdate_btn position-absolute"
                                                onClick={() => {
                                                    this.openSendAuditModal();
                                                }}
                                            >
                                                <i className="fas fa-file-signature"></i>
                                                Send To Auditor
                                            </Button>
                                        )}
                                    {this.state.pageType == 'audit' && this.state.enableForHOD === true && (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="bulk_update pl-2"
                                            onClick={() => {
                                                this.bulkSend()
                                            }}
                                        >
                                            <i
                                                className={this.state.bulkUpdateForAudit === false
                                                    ? 'fas fa-sync' : 'fas fa-list-ul'
                                                }
                                            ></i>
                                            {this.state.bulkUpdateForAudit === false ? 'Send For Audit' : 'List view'}
                                        </Button>
                                    )}

                                    {(this.state.bulkUpdate === false && this.state.bulkUpdateForAudit === false) ? (
                                        <CustomisedDatatable
                                            className="table table-bordered table-striped mt-1"
                                            tableRecords={data}
                                            sortRows={[
                                                'taskCode',
                                                'complianceCode',
                                                'dueDate',
                                            ]}
                                        />
                                    ) : (
                                        <CustomisedReactTableWithPaginationAndRowSelection
                                            onchangeCheckbox={(row) => {
                                                this.setState({
                                                    selectedTasks:
                                                        row.map((itemRow) => ({
                                                            id: itemRow.id,
                                                            entityId: itemRow.entityId,
                                                        })),
                                                });
                                            }}
                                            searching={true}
                                            paging={false}
                                            displayEntries={false}
                                            className="table table-bordered table-striped report-compliances_table"
                                            tableData={dataWithCheckbox}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </>

                </Row>
                <QuickSelectedUpdateModal
                    isModalOpen={this.state.quickUpdateUserModal}
                    onModalDismiss={() => this.closeQuickUpdateModal()}
                    modalName={__t(
                        'form_labels.reporting.quick_update.modal_title'
                    )}
                    sortedDepartmentListDropdown={
                        this.props.sortedDepartmentListDropdown
                    }
                    quickUpdateSelectedData={this.state.quickUpdateSelectedData}
                />
                <SubTaskList
                    modalName={__t('form_labels.reporting.sub_task_list')}
                    isModalOpen={this.state.isOpenSubTaskModal}
                    onModalDismiss={() => this.closeSubTaskModal()}
                    taskData={this.state.openTaskData}
                />
                <BulkSendAuditModal
                    modalName={__t(
                        'form_labels.reporting.more_menu.send_for_audit'
                    )}
                    isModalOpen={this.state.isSendAuditModalOpen}
                    entityId={this.state.entityId}
                    onModalDismiss={() => this.closeSendAuditModal()}
                    onFormValid={(values) => {
                        this.props.sendForAuditTaskByTaskIdsRequest(this.state.taskIds, values);
                    }}
                    bulkSendTaskUsers={this.state.bulkSendTaskUsers}
                />
            </React.Fragment>
        );
    }
}
const mapStatetoProps = createStructuredSelector({
    taskSearchApiResponse: makeTaskSelectField('taskSearchApiResponse'),
    quickUpdateTaskApiResponse: makeTaskSelectField('quickUpdateTaskApiResponse'),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
    sortedDepartmentListDropdown: selectSortedDepartmentList(),
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    allCompliancesCategoriesList: makeLawComplianceSelectField('allCompliancesCategoriesList'),
    allCompliancesTypesList: makeLawComplianceSelectField('allCompliancesTypesList'),
    taskSendAuditApiResponse: makeTaskSelectField('taskSendAuditApiResponse'),
});
const withConnect = connect(mapStatetoProps, {
    getReportTaskSearchListRequest,
    getReviewTaskSearchListRequest,
    getAuditTaskSearchListRequest,
    getDepartmentListByStatusRequest,
    getRiskRatingsRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    clearRiskRatingResponse,
    getTaskStatusListRequest,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
    sendForAuditTaskByTaskIdsRequest,
    clearTaskSearchApiResponse
});
export default compose(
    withConnect,
    withEntityReducer,
    withEntitySaga,
    withTaskReducer,
    withTaskSaga,
    withDepartmentReducer,
    withDepartmentSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(Index);
