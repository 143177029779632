import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Label } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { compose } from 'redux';
import __t from 'i18n/translator';
import injectSaga from 'helpers/injectSaga';
import injectReducer from 'helpers/injectReducer';
import taskReducer from 'store/Task/reducer';
import taskSaga from 'store/Task/saga';
import CustomisedDatatable from 'components/Application/CustomisedDatatable';
import {
    getTaskUserDetailsByTaskIdRequest,
    getRiskRatingsRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    clearRiskRatingResponse,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
    getTaskStatusListRequest
} from 'store/actions';
import {
    AUDITED_AND_CLOSED,
    CLOSED,
    PENDING_FOR_AUDIT,
    PENDING_WITH_FIRST_APPROVER,
    PENDING_WITH_SECOND_APPROVER,
    SEND_BACK_BY_AUDITOR_TO_APPROVER,
    SEND_BACK_TO_APPROVER,
} from 'constants/databaseConstants';
import ReactTooltip from 'react-tooltip';
import { PENDING_TO_TRIGGERED, SUCCESS } from 'constants/commonConstants';
import { getDateFrom } from 'helpers/dateTimeHelper';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ViewTaskUser from 'app/Events/ViewTaskUser';
import { makeTaskSelectField, selectSortedTaskStatusList } from 'store/Task/selector';
import { compareString, redirectUrlWindowOpen } from 'helpers/generalUtils';
import { makeLawComplianceSelectField } from 'store/LawCompliance/selector';
import lawComplianceSaga from 'store/LawCompliance/saga';
import lawComplianceReducer from 'store/LawCompliance/reducer';
const lawComplianceKey = 'lawCompliance';
const withLawComplianceReducer = injectReducer({
    key: lawComplianceKey,
    reducer: lawComplianceReducer,
});
const withLawComplianceSaga = injectSaga({
    key: lawComplianceKey,
    saga: lawComplianceSaga,
});
const listColumns = [
    {
        label: 'Code',
        field: 'taskCode',
    },
    {
        label: 'Entity',
        field: 'entityName',
    },
    {
        label: 'Location',
        field: 'cityName',
    },
    {
        label: 'Compliance Code',
        field: 'complianceCode',
    },
    {
        label: 'Title',
        field: 'complianceTitle',
        attributes: {
            className: 'grid_title sorting',
        },
    },
    {
        label: 'Classification',
        field: 'complianceClass',
    },
    {
        label: 'Due Date',
        field: 'dueDate',
    },
    {
        label: 'Task Type',
        field: 'parentTaskId',
    },
    {
        label: 'Risk Level',
        field: 'riskLevel',
    },
    {
        label: 'Role',
        field: 'role',
    },
    {
        label: 'Status',
        field: 'taskStatusId',
    },
    {
        label: 'Action',
        attributes: {
            className: 'action_col',
        },
        field: 'actions',
        sort: 'disabled',
    },
];

const taskKey = 'task';
const withTaskReducer = injectReducer({
    key: taskKey,
    reducer: taskReducer,
});
const withTaskSaga = injectSaga({ key: taskKey, saga: taskSaga });
class TaskListHODManagementDetails extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();
        this.state = {
            taskList: [],
            userDepartments: [],
            userDepartmentEntities: [],
            sortBy: {
                key: 'createdAt',
                value: 'DESC',
            },
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
            compliancesCategoriesList: [],
            compliancesTypesList: [],
            riskRatingsList: [],
            taskStatusList: []
        };
    }
    componentDidMount() {
        this.props.getComplianceCategoriesRequest();
        this.props.getComplianceTypesRequest();
        this.props.getRiskRatingsRequest();
        this.props.getTaskStatusListRequest();
        this.setState(
            {
                userDepartments: this.props.userDepartments,
                taskList: []
            },
            () => {
                this.setState({
                    taskList: this.generateTable(this.props.tableData),
                });
            }
        );
    }
    componentWillUnmount() {
        this.props.clearRiskRatingResponse();
        this.props.clearComplianceCategoryResponse();
        this.props.clearComplianceTypeResponse();
    }
    openViewTaskUserModal = (data) => {
        this.setState({
            isViewTaskUserModalOpen: true,
            viewTaskUserData: data,
        });
    };
    closeViewTaskUserModal() {
        this.setState({
            isViewTaskUserModalOpen: false,
            viewTaskUserData: {},
        });
    }
    generateTable = (records) => {
        let taskRecords = [];
        const locationList = this.props.locationList;
        taskRecords = records.map((taskData, i) => {
            let roleNames = this.getRoleName(taskData);

            let type = 'report';
            if (
                [PENDING_FOR_AUDIT, AUDITED_AND_CLOSED].includes(
                    taskData.taskStatusId
                )
            ) {
                type = 'audit';
            }
            if (
                [
                    PENDING_WITH_FIRST_APPROVER,
                    PENDING_WITH_SECOND_APPROVER,
                    SEND_BACK_TO_APPROVER,
                    SEND_BACK_BY_AUDITOR_TO_APPROVER,
                ].includes(taskData.taskStatusId)
            ) {
                type = 'review';
            }
            let actions = (
                <div className="tbl_action">
                    {(taskData.taskStatusId == CLOSED ||
                        taskData.taskStatusId == AUDITED_AND_CLOSED) && (
                            <a
                                href={() => false}
                                title={__t('common.view')}
                                className="btn btn-link text-success tbl-action"
                                onClick={() => {
                                    redirectUrlWindowOpen(
                                        '/compliances/' + type + '/' + taskData.id,
                                        "_blank"
                                    );
                                }}
                            >
                                <i className="fas fa-eye"></i>
                            </a>
                        )}
                    {taskData.taskStatusId != CLOSED &&
                        taskData.taskStatusId != AUDITED_AND_CLOSED && (
                            <a
                                href={() => false}
                                title={__t('common.view')}
                                className="btn btn-link text-primary tbl-action"
                                onClick={() => {

                                    redirectUrlWindowOpen(
                                        '/compliances/' +
                                        type +
                                        '/' +
                                        taskData.id,
                                        "_blank"
                                    );
                                }}
                            >
                                <i className="fas fa-file-import"></i>
                            </a>
                        )}
                </div>
            );
            let taskCodeVal = (
                <span searchvalue={taskData.taskCode} className="code_td">
                    {taskData.taskCode}
                </span>
            );
            const findTaskStatusName = this.state?.taskStatusList
                && this.state?.taskStatusList.length > 0 ? this.state?.taskStatusList.find((element) => element.value == taskData.taskStatusId)?.label ?? "" : "";
            let infoVal = (
                <div
                    className="status_info"
                    searchvalue={findTaskStatusName}
                >
                    <span>{findTaskStatusName}</span>
                    <a
                        href={() => false}
                        onClick={() => {
                            this.props.getTaskUserDetailsByTaskIdRequest(taskData.id)
                        }}
                        className="text-primary"
                    >
                        <i className="fas fa-info-circle"></i>
                    </a>
                </div>
            );
            let complianceAction = (
                <a
                    href={() => false}
                    searchvalue={
                        taskData?.complianceAllocationLawComplianceCode
                    }
                    title="View Details"
                    className="tbl-link"
                    onClick={() =>
                        window.open(
                            '/statutory-compliances/view-details/' +
                            taskData?.complianceAllocationLawComplianceId,
                            '_blank'
                        )
                    }
                >
                    {taskData?.complianceAllocationLawComplianceCode}{' '}
                </a>
            );
            const cityName = locationList?.filter((data) => data.id === taskData?.complianceAllocationEntityLocationCityId)[0]?.cityName

            // let complianceTypeId =
            //     taskData.complianceVersionLawComplianceTypeName == null
            //         ? taskData.complianceAllocationLawComplianceTypeId
            //         : taskData.complianceVersionLawComplianceTypeId;
            // let complianceTypeName = this.state.compliancesTypesList.find((cType) => cType.id === complianceTypeId)?.complianceTypeName || '';

            let complianceCategoryId = taskData.complianceVersionLawComplianceCategoryId == null
                ? taskData.complianceAllocationLawComplianceCategoryId
                : taskData.complianceVersionLawComplianceCategoryId;
            let complianceCategoryName = this.state.compliancesCategoriesList.find((c) => c.value === complianceCategoryId)?.label || '';

            let complianceRiskRatingId = taskData.complianceVersionLawComplianceRiskRatingId ==
                null
                ? taskData.complianceAllocationLawComplianceRiskRatingId
                : taskData.complianceVersionLawComplianceRiskRatingId;
            let complianceRiskRatingName = this.state.riskRatingsList.find((c) => c.value === complianceRiskRatingId)?.label || '';

            return {
                id: taskData.id,
                taskCode: taskCodeVal,
                companyGroupName:
                    taskData?.ComplianceAllocationEntityCompanyGroupName,
                entityName: taskData.complianceAllocationEntityName,
                complianceCode: complianceAction,
                countryName:
                    taskData.complianceAllocationEntityLocationCountryName,
                stateName: taskData.complianceAllocationEntityLocationStateName,
                cityName: cityName ? cityName : "",
                complianceTitle:
                    taskData.parentTaskId === null
                        ? taskData.complianceVersionLawComplianceTitle == null
                            ? taskData.complianceAllocationLawComplianceTitle
                            : taskData.complianceVersionLawComplianceTitle
                        : taskData.title,
                complianceClass: complianceCategoryName,
                // complianceType: complianceTypeName,
                dueDate: taskData.dueDate ? (
                    <Label searchvalue={taskData.dueDate}>
                        {getDateFrom(taskData.dueDate)}
                    </Label>
                ) : (
                    <Label searchvalue={''}>{PENDING_TO_TRIGGERED}</Label>
                ),
                riskLevel: complianceRiskRatingName,
                taskStatusId: infoVal,
                parentTaskId:
                    taskData.parentTaskId === null
                        ? 'Statutory'
                        : 'Non-Statutory',
                role: roleNames.length > 0 ? roleNames.join('<br/>') : '-',
                actions: actions,
            };
        });
        return taskRecords;
    };

    getRoleName = (taskData) => {
        let roles = [];
        const departmentIds =
            taskData.complianceAllocationDepartmentId != '' &&
                taskData.complianceAllocationDepartmentId != null
                ? taskData.complianceAllocationDepartmentId.split(',')
                : [];
        const collaboratorUserIds =
            taskData.collaboratorUserId != '' &&
                taskData.collaboratorUserId != null
                ? taskData.collaboratorUserId.split(',').map(Number)
                : [];
        if (taskData.submitterId && taskData.submitterId == this.state.loginUserId) {
            roles.push('Submitter');
        } else if (taskData.firstApproverUserId && taskData.firstApproverUserId == this.state.loginUserId) {
            roles.push('Reviewer');
        } else if (taskData.secondApproverUserId && taskData.secondApproverUserId == this.state.loginUserId) {
            roles.push('Approver');
        } else if (taskData.auditorId && taskData.auditorId == this.state.loginUserId) {
            roles.push('Auditor');
        } else if (
            departmentIds.some((department) => {
                let departmentHod = this.state.userDepartments?.find(
                    (departmentEntity) =>
                        departmentEntity.entityId ==
                        taskData.complianceAllocationEntityId &&
                        departmentEntity.departmentId == department
                );
                if (departmentHod) {
                    return true;
                }
            })
        ) {
            roles.push('HOD');
        } else if (
            taskData.collaboratorUserId && taskData.collaboratorUserId != '' &&
            collaboratorUserIds.includes(this.state.loginUserId)
        ) {
            roles.push('Collaborator');
        } else if (taskData.createdBy && taskData.createdBy == this.state.loginUserId) {
            roles.push('Task Creator');
        }
        return roles;
    };
    UNSAFE_componentWillReceiveProps(nextprops) {
        if (
            nextprops.riskRatingsList &&
            this.props.riskRatingsList !== nextprops.riskRatingsList

        ) {

            this.setState({
                riskRatingsList: nextprops.riskRatingsList
                    ?.map((opt) => ({
                        value: opt.id,
                        label: opt.riskRatingName,
                    }))
                    .sort((item1, item2) =>
                        compareString(item1.label, item2.label)
                    ),
            });
        }

        if (
            nextprops.allCompliancesTypesList &&
            this.props.allCompliancesTypesList !== nextprops.allCompliancesTypesList

        ) {
            this.setState({
                compliancesTypesList: nextprops.allCompliancesTypesList,
            });
        }

        if (
            nextprops.allCompliancesCategoriesList &&
            this.props.allCompliancesCategoriesList !==
            nextprops.allCompliancesCategoriesList
        ) {
            this.setState({
                compliancesCategoriesList:
                    nextprops.allCompliancesCategoriesList
                        ?.map((opt) => ({
                            value: opt.id,
                            label: opt.complianceCategoryName,
                        }))
                        .sort((item1, item2) =>
                            compareString(item1.label, item2.label)
                        ),
            });
        }
        if (nextprops.tableData) {
            this.setState(
                {
                    userDepartments: nextprops.userDepartments,
                    taskList: this.generateTable([]),
                },
                () => {
                    this.setState({
                        taskList: this.generateTable(nextprops.tableData),
                    });
                }
            );
        }
        if (
            nextprops.taskUserDetailsApiResponse &&
            nextprops.taskUserDetailsApiResponse !==
            this.props.taskUserDetailsApiResponse
        ) {
            if (nextprops.taskUserDetailsApiResponse.responseType === SUCCESS) {
                this.setState({
                    isViewTaskUserModalOpen: true,
                    viewTaskUserData:
                        nextprops.taskUserDetailsApiResponse.data?.taskUserData,
                });
            }
        }
        if (
            nextprops.sortedTaskStatusListDropdown &&
            nextprops.sortedTaskStatusListDropdown !==
            this.props.sortedTaskStatusListDropdown
        ) {
            this.setState({
                taskStatusList: nextprops.sortedTaskStatusListDropdown
            })
        }
    }
    render() {
        const data = {
            columns: listColumns,
            rows: this.state.taskList,
        };
        return (
            <React.Fragment>
                <ReactTooltip />
                <Row>
                    <Col lg="12">
                        <Link
                            to="#"
                            className="btn btn-primary mb-3 dashboard_back_btn"
                            onClick={() => {
                                this.props.closeOverallTask();
                            }}
                        >
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
                                    <path d="M8 1L2 7M2 7L8 13M2 7H17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </span>
                            Back
                        </Link>
                        <Card>
                            <CardBody className="custom_table">
                                <CustomisedDatatable
                                    tableRecords={data}
                                    sortRows={[
                                        'dueDate',
                                        'complianceCode',
                                        'taskCode',
                                        'taskStatusId',
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ViewTaskUser
                    modalName={__t('form_labels.reporting.view_task_user')}
                    isModalOpen={this.state.isViewTaskUserModalOpen}
                    taskUserData={this.state.viewTaskUserData}
                    onModalDismiss={() => this.closeViewTaskUserModal()}
                />
            </React.Fragment>
        );
    }
}

const mapStatetoProps = createStructuredSelector({
    taskUserDetailsApiResponse: makeTaskSelectField('taskUserDetailsApiResponse'),
    riskRatingsList: makeLawComplianceSelectField('riskRatingsList'),
    allCompliancesCategoriesList: makeLawComplianceSelectField('allCompliancesCategoriesList'),
    allCompliancesTypesList: makeLawComplianceSelectField('allCompliancesTypesList'),
    sortedTaskStatusListDropdown: selectSortedTaskStatusList(),
});
const withConnect = connect(mapStatetoProps, {
    getTaskUserDetailsByTaskIdRequest,
    getRiskRatingsRequest,
    getComplianceCategoriesRequest,
    getComplianceTypesRequest,
    clearRiskRatingResponse,
    clearComplianceCategoryResponse,
    clearComplianceTypeResponse,
    getTaskStatusListRequest
});
export default compose(
    withConnect,
    withTaskReducer,
    withTaskSaga,
    withLawComplianceReducer,
    withLawComplianceSaga,
    withRouter
)(TaskListHODManagementDetails);